import React from 'react';
import Img from "gatsby-image";

const Poloroid = (props) => {
  const {
    image,
    dark,
    name,
    position,
    small
  } = props;

  let totalClass = "poloroid";

  if(dark) totalClass += " dark";
  if(small) totalClass += " small";
  
  return (
    <div className={totalClass}>
      <div className="pol-img-cont">
        <Img
          fluid={image}
        />
      </div>
      <div className="pol-caption">
        <strong>{name}</strong>
        <p>{position}</p>
      </div>
    </div>
  )
};

export default Poloroid;