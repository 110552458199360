import React from 'react';
import { Heading, Text } from "@chakra-ui/core";
import { Flex } from "@chakra-ui/react";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import Layout from '../components/layout';
import {Carousel} from 'react-bootstrap';
import Poloroid from '../components/template-parts/poloroid';

const OurBrotherhood = React.forwardRef((props, ref) => {
  
  const data = useStaticQuery(graphql`
    query {
      wpPage(title: {eq: "brotherhood-meta"}) {
          brotherhoodMeta {
            bannerImages {
              firstImage {
                id
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              secondImage {
                id
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              thirdImage {
                id
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              fourthImage {
                id
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              fifthImage {
                id
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              sixthImage {
                id
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              seventhImage {
                id
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              eighthImage {
                id
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            featuredBrother {
              portrait {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              name
              position
              quote
            }
            eboard {
              firstSlot {
                image {
                  id
                  localFile {
                    childImageSharp {
                      fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                name
                position
              }
              secondSlot {
                image {
                  id
                  localFile {
                    childImageSharp {
                      fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                name
                position
              }
              thirdSlot {
                image {
                  id
                  localFile {
                    childImageSharp {
                      fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                name
                position
              }
              fourthSlot {
                image {
                  id
                  localFile {
                    childImageSharp {
                      fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                name
                position
              }
              fifthSlot {
                image {
                  id
                  localFile {
                    childImageSharp {
                      fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                name
                position
              }
              sixthSlot {
                image {
                  id
                  localFile {
                    childImageSharp {
                      fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                name
                position
              }
            }
            philanthropy {
              quote
              brother {
                brotherName
                position
                brotherImage {
                  localFile {
                    childImageSharp {
                      fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
              slider {
                slotFive {
                  id
                  localFile {
                    childImageSharp {
                      fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                slotFour {
                  id
                  localFile {
                    childImageSharp {
                      fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                slotOne {
                  id
                  localFile {
                    childImageSharp {
                      fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                slotThree {
                  id
                  localFile {
                    childImageSharp {
                      fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                slotTwo {
                  id
                  localFile {
                    childImageSharp {
                      fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
  `);

  const { brotherhoodMeta: { 
    bannerImages, featuredBrother, philanthropy, eboard 
  } } = data.wpPage;

  return (
    <Layout >
      <section className="page-container" >
        <div className="inner-banner">
          <Carousel slide={true} indicators={true}>
            {Object.values(bannerImages).map((image, i) => {
              if(!image) return null;
              const src = image.localFile.childImageSharp.fluid;
              const id = image.id;

              return (
                <Carousel.Item key={id} interval={5000}>
                  <Img 
                    fluid={src}
                    alt={`banner-img-${id}`}
                  />
                </Carousel.Item>
              )
            })}
          </Carousel>
        </div>

        <div className="quote-section-container">
          <Flex 
            flexDirection={["column", "row"]}
            className="gl-w quote-section-wrapper"
          >
            <div className="half-col">
              <Poloroid 
                image={featuredBrother.portrait.localFile.childImageSharp.fluid}
                dark={true}
                name={featuredBrother.name}
                position={featuredBrother.position}
              />
            </div>
            <div className="half-col quote-block">
              <Text 
                dangerouslySetInnerHTML={{ __html: featuredBrother.quote }}
              />
            </div>
          </Flex>
        </div>

        

        <div className="composite-section-container">
          <Flex flexDirection="column" className="composite-section gl-w">
            <Heading as="h2">Delta Beta</Heading>
            <Heading as="h2">2020 Executive Board</Heading>

            <Flex 
              flexDirection={["row", "row"]}
              flexWrap="wrap"
              className="eboard-row"
            >
              {Object.values(eboard).map(member => {
                if(!member) return (null);
                const id = member.image.id;

                return (
                  <Poloroid 
                    key={id}
                    small={true}
                    image={member.image.localFile.childImageSharp.fluid}
                    dark={true}
                    name={member.name}
                    position={member.position}
                  />
                )
              })}
            </Flex>
          </Flex>
        </div>

        <div className="philanthropy-container">
          
          <Flex 
            className="philanthropy gl-w"
            flexDirection={["column", "row"]}
          >
            <div className="half-col">
              <Heading as="h3">Philanthropy</Heading>
              <Flex 
                className="flex-col quote-section-container"
                flexDirection="row"
              >
                <div className="half-col">
                  <Poloroid 
                    image={philanthropy.brother.brotherImage.localFile.childImageSharp.fluid}
                    dark={true}
                    small={true}
                    name={philanthropy.brother.brotherName}
                    position={philanthropy.brother.position}
                  />
                </div>
                <div className="half-col quote-block-sm">
                  <Text dangerouslySetInnerHTML={{ __html: philanthropy.quote }}/>
                </div>
              </Flex>
            </div>
            <div className="half-col slider-half">
              <Carousel slide={true} indicators={true}>
                {Object.values(philanthropy.slider).map((image, i) => {
                  
                  if(!image) return (null);
                  const src = image.localFile.childImageSharp.fluid;
                  const id = image.id;

                  return (
                    <Carousel.Item key={id} interval={5000}>
                      <Img 
                        fluid={src}
                        alt={`banner-img-${id}`}
                      />
                    </Carousel.Item>
                  )
                })}
              </Carousel>
            </div>
          </Flex>
        </div>
      </section>
    </Layout>
  )
});

export default OurBrotherhood;